import { Injectable, OnDestroy } from "@angular/core";
import { Howl, Howler } from "howler";
import { BehaviorSubject, timer } from "rxjs";

import { IPlaybackStateDTO } from "./audio-playback-state.interface";
import { MinuteSecondsPipe } from "./time-format.pipe";

@Injectable()
export class AudioPlaybackService implements OnDestroy {
  initialVolumeLevel = 0.2;
  private state: IPlaybackStateDTO = {
    currentTime: 0,
    duration: "00:00",
    error: false,
    loading: false,
    pause: false,
    playing: false,
    sliderMaxTime: 1,
    timePassed: 0,
    volume: this.initialVolumeLevel
  };

  private stateChange: BehaviorSubject<IPlaybackStateDTO> = new BehaviorSubject(
    this.state
  );

  currentStateChange = this.stateChange.asObservable();
  sound!: Howl;
  playedHowls = new Map();
  timeSource = timer(0, 1000);

  constructor(private minSec: MinuteSecondsPipe) {}

  ngOnDestroy() {
    Howler.unload();
  }

  updateStateEvents(event: string, value?: number): void {
    const val = value;
    switch (event) {
      case "onload":
        const duration = this.minSec.transform(
          Math.round(this.sound.duration())
        );
        this.state.sliderMaxTime = Math.round(this.sound.duration());
        this.state.duration = duration;
        this.state.loading = false;
        break;
      case "onplay":
        this.state.loading = false;
        this.timeSource.subscribe(() => this.step());
        this.state.playing = true;
        this.state.pause = false;
        break;
      case "onpause":
        this.state.playing = false;
        this.state.pause = true;
        break;
      case "onseek":
        break;
      case "onend":
        this.state.pause = true;
        this.state.playing = false;
        break;
      case "onplayerror":
        this.state.error = true;
        break;
      case "onstop":
        this.state.playing = false;
        this.state.pause = false;
        break;
      case "currenttime":
        const currentime = val ? Math.round(val) : 1;
        this.state.currentTime = currentime;
        break;
    }
    this.stateChange.next(this.state);
  }

  play(soundURL: string) {
    if (!this.playedHowls.get(soundURL)) {
      this.playedHowls.set(
        soundURL,
        new Howl({
          format: ["mp3"],
          html5: true,
          onend: () => this.updateStateEvents("onend"),
          onload: () => this.updateStateEvents("onload"),
          onloaderror: () => this.updateStateEvents("onplay"),
          onpause: () => this.updateStateEvents("onpause"),
          onplay: () => this.updateStateEvents("onplay"),
          onplayerror: () => this.updateStateEvents("onplayerror"),
          onseek: () => this.updateStateEvents("onseek"),
          onstop: () => this.updateStateEvents("onstop"),
          preload: false,
          src: soundURL
        })
      );
    }
    this.sound = this.playedHowls.get(soundURL);
    this.sound.play();
  }

  pause() {
    this.sound.pause();
    this.state.playing = false;
    this.stateChange.next(this.state);
  }

  stop() {
    this.sound.stop();
  }

  onVolumeChange(value: number) {
    Howler.volume(value);
    this.state.volume = value;
  }

  killSubscriptions() {
    this.resetState();
  }

  private resetState() {
    if (this.sound) {
      this.sound.stop();
    }
    this.state = {
      currentTime: 0.0,
      duration: "00:00",
      error: false,
      loading: false,
      pause: false,
      playing: false,
      sliderMaxTime: 0.0,
      timePassed: 0,
      volume: this.initialVolumeLevel
    };
    this.stateChange.next(this.state);
  }

  seekTo(num: number) {
    this.sound.seek(num);
    this.updateStateEvents("currenttime", num);
  }

  step() {
    if (this.sound.playing()) {
      this.updateStateEvents("currenttime", this.sound.seek() as number);
    }
  }

  updateCurrentTime(num: number) {
    this.updateStateEvents("currenttime", num);
  }
}
