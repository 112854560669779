<div class="row flex" style="justify-content: center;">
  <mat-spinner *ngIf="busy" ></mat-spinner>
</div>
<pdf-viewer #pdfViewerElement id="pdf-viewer"
  [src]= "src"
  [render-text]="!smallScreen"
  (error)="onError($event)"
  style="display: block;margin-top: 1em;"
  [fit-to-page]="smallScreen"
  (on-progress)="onProgress($event)"
  (after-load-complete)="callBackFn($event)"
  [ngStyle]="{visibility: !busy ? 'visible' : 'hidden'}"
  >
</pdf-viewer>
<div [ngStyle]="{visibility: showNotFound ? 'visible' : 'hidden'}" style="padding-left: 30px; visibility: hidden;">
  <h1>File not found!</h1>
  <div>Please try again later, or <a href="/contact">contact us</a> if problem persist.</div>
</div>
