import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { distinctUntilChanged, flatMap, map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class ScrollSpyService {
  spyOn(element: Element): Observable<boolean> {
    return new Observable(observer => {
      const intersectionObserver = new IntersectionObserver(
        entries => {
          observer.next(entries);
        },
        { rootMargin: "0px 0px -50% 0px" }
      );

      intersectionObserver.observe(element);

      return () => {
        intersectionObserver.disconnect();
      };
    }).pipe(
      flatMap(entries => entries as IntersectionObserverEntry[]),
      map(entry => entry.isIntersecting),
      distinctUntilChanged()
    );
  }
}
