
<div *ngIf="!smallScreen; else mobileAudioPlayer" class="column audio-bar" [class.disabled]="playbackState.loading" style="justify-content: center center;" >
  <div *ngIf="playbackState?.error" style="display:flex; flex-direction: row; justify-content: center">
    <mat-icon style="color:red">error_outline</mat-icon><h4>Failed to read audio file unable to play title.Please try later.</h4>
  </div>
  <div class="row" *ngIf="!playbackState?.error" style="justify-content: center center">
    <div class="row">
      <mat-divider class="divider-vertical desktop-only" vertical style="transform: scale(1); margin-left: 2em"></mat-divider>
      <div *ngIf="!playbackState?.playing" style="width:50px">
        <button class="button-playstop" mat-icon-button (click)="play()">
          <mat-icon style="margin-bottom: 5px;">play_arrow</mat-icon>
        </button>
      </div>
      <div *ngIf="playbackState?.playing" style="width:50px">
        <button class="button-playstop" mat-icon-button (click)="pause()"><mat-icon>pause</mat-icon>
        </button>
      </div>
      <mat-divider class="divider-vertical desktop-only" vertical></mat-divider>
    </div>
    <div class="row" style="justify-content: space-evenly">
        <span class="duration" style="align-self: center; margin-left:16px;width:50px">
          {{!playbackState.currentTime ? "00:00" : (playbackState?.currentTime)! | minuteSeconds}}
        </span>
        <mat-slider aria-label= "will let you move forward or backwords in audio track"
          class="time-slider"
          min="0"
          [max]="!playbackState?.sliderMaxTime ? 100 : playbackState?.sliderMaxTime"
          step = "1"
          [value]="!playbackState?.currentTime? 0 : playbackState?.currentTime"
          (input)="onSliderChangeEnd($event)"
          (change)="onChange($event)"
          >
        </mat-slider>
        <span class="duration" style="align-self: center; width: 50px">{{playbackState?.duration}}</span>
    </div>
    <div class="row" style="justify-content: space-evenly">
      <mat-divider aria-label= "will adjust volume" class="divider-vertical desktop-only" vertical></mat-divider>
      <button mat-icon-button><mat-icon mat-list-icon style="margin-top: 5px;">volume_up</mat-icon></button>
      <mat-slider aria-label= "will let you change volume"
        class="volume-slider"
        min="0"
        [max]="1"
        step="0.1"
        [value]="playbackState?.volume"
        (input)="onVolumeChange($event)"></mat-slider>
    </div>
  </div>
</div>
<div>
  <mat-progress-bar mode="indeterminate" *ngIf="playbackState.loading"></mat-progress-bar>
</div>
<ng-template #mobileAudioPlayer>
  <div class="mobile-audio" [class.disabled]="playbackState.loading">
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <mat-icon matTooltip="Play CMI audio file">play_circle_outline</mat-icon>
      <span style="margin-left: 10px;">Listen to CMI</span>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="audio-mobile" style="border-radius: 4px;" style="justify-content: center;">
      <div class="row flex" style="justify-content: space-between;align-content: center;">
        <div class="row flex" style="justify-content: space-between;">
          <div class="row">
            <span class="duration">
              {{!playbackState.currentTime ? "00:00" : (playbackState?.currentTime)! | minuteSeconds}}
            </span>
            <mat-divider aria-label= "will adjust volume" class="divider-vertical" vertical
              style="height:30px; margin-top:10px;">
            </mat-divider>
            <span class="duration">{{playbackState?.duration}}</span>
          </div>
          <div class="row" *ngIf="!playbackState?.playing; else pausebutton" style="align-self: flex-end;">
            <button mat-stroked-button (click)="play()">
            <mat-icon  inline="true">play_arrow</mat-icon>PLAY
            </button>
          </div>
          <ng-template #pausebutton>
              <button mat-stroked-button (click)="pause()">
                <mat-icon inline="true">pause</mat-icon>PAUSE
              </button>
          </ng-template>
        </div>
      </div>
      <div class="row flex">
        <mat-slider aria-label= "will let you move forward or backwords in audio track"
          class="time-slider"
          min="0"
          [max]="!playbackState?.sliderMaxTime ? 100 : playbackState?.sliderMaxTime"
          step = "1"
          [value]="!playbackState?.currentTime? 0 : playbackState?.currentTime"
          (input)="onSliderChangeEnd($event)"
          (change)="onChange($event)"
          style="width:100%">
        </mat-slider>
      </div>
    </div>
  </mat-expansion-panel>
  </div>
</ng-template>
