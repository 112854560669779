import { NgModule, Injector } from '@angular/core';
import {createCustomElement} from '@angular/elements';
import { BrowserModule } from '@angular/platform-browser';

import { ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatFormFieldModule } from "@angular/material/form-field";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";

import { HttpClientModule } from "@angular/common/http";

import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSliderModule } from "@angular/material/slider";
import { MatExpansionModule } from "@angular/material/expansion";
/*
import { MatCardModule } from "@angular/material/card";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
*/

import { PdfViewerModule } from "ng2-pdf-viewer";

import { DocumentSearchComponent } from './document-search/document-search.component';
import { HighlighterPipe } from "./document-search/highlighter";
import { AudioControlComponent } from './audio-control/audio-control.component';
import { MinuteSecondsPipe } from "./audio-control/time-format.pipe";
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';
import { DocumentNavigationComponent } from './document-navigation/document-navigation.component';

@NgModule({
  declarations: [
    DocumentSearchComponent,
    HighlighterPipe,
    AudioControlComponent,
    MinuteSecondsPipe,
    PdfViewerComponent,
    DocumentNavigationComponent
  ],
  imports: [
    BrowserModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,

    PdfViewerModule,

    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatListModule,
    MatMenuModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatSliderModule,
    MatSlideToggleModule
/*
    MatSortModule,
    MatTableModule,
    MatToolbarModule,
    MatDividerModule,
    MatDialogModule,
    MatCardModule,
    MatTabsModule,
    MatSidenavModule,
*/

  ],
  entryComponents: [DocumentSearchComponent, AudioControlComponent, PdfViewerComponent, DocumentNavigationComponent],
  providers: []
})
export class AppModule { 
  constructor(injector: Injector) {
    const custom1 = createCustomElement(DocumentSearchComponent, {injector: injector});
    customElements.define('moa-document-search', custom1);
    const custom2 = createCustomElement(AudioControlComponent, {injector: injector});
    customElements.define('moa-audio-control', custom2);
    const custom3 = createCustomElement(PdfViewerComponent, {injector: injector});
    customElements.define('moa-pdf-viewer', custom3);
    const custom4 = createCustomElement(DocumentNavigationComponent, {injector: injector});
    customElements.define('moa-document-navigation', custom4);
  }
  ngDoBootstrap() {}
}
