import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input
} from "@angular/core";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Observable } from "rxjs";

import { ScrollSpyService } from "./scroll-spy.service";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  //selector: "moa-document-navigation",
  //styleUrls: ["./document-navigation.component.scss"],
  templateUrl: "./document-navigation.component.html"
})
export class DocumentNavigationComponent {
  private readonly _scrollSpy: ScrollSpyService;
  private readonly _cdr: ChangeDetectorRef;
  
  @Input() cmiVersion = "2";

  smallScreen = false;
  list?: Observable<any>;
  results: IDocumentHeading[] = [];
  headerHeight = 30;
  currentPage = '';

  @HostListener("window:scroll", [])
  onWindowScroll() {
    this.updateBrowseProgress();
    this._cdr.markForCheck();
  }

  constructor(scrollSpy: ScrollSpyService, private cdr: ChangeDetectorRef, breakpointObserver: BreakpointObserver) {
    this._scrollSpy = scrollSpy;
    this._cdr = cdr;
    breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small])
      .subscribe(result => {
        this.smallScreen = result.matches ? true : false;
        this._cdr.markForCheck();
      });
  }

  ngOnInit() {

    this.currentPage = window.location.href;
    const allHeadings =
      this.cmiVersion === "3"
        ? document.querySelectorAll(
          "article h1, article h2, article h3"
        )
        : document.querySelectorAll("article h1, article h2");
    
    let headings = Array.from(allHeadings);
    if (this.cmiVersion === "3") {
      headings = [];
      let count = 0;
      allHeadings.forEach(e => {
        if (e.nodeName.toLocaleLowerCase() === "h1") {
          count++;
        }
        if (count >= 2) {
          if (e.nodeName.toLocaleLowerCase() === "h2") {
            headings.push(e);
          }
          if (e.nodeName.toLocaleLowerCase() === "h3") {
            headings.push(e);
          }
        }
      });
    } else {
      headings = [];
      let count = 0;
      allHeadings.forEach(e => {
        if (e.nodeName.toLocaleLowerCase() === "h1") {
          if (count === 0) {
            headings.push(e);
          }
          count++;
        } else {
          headings.push(e);
        }
      });
    }

    const toc = headings.map((heading, i) => {
      const h = heading as HTMLElement;
      const parent = h.parentNode as HTMLElement;
      let text = h.innerText;
      if (this.cmiVersion === "2") {
        if (i === 0) {
          text = 'Consumer Medicine Information (CMI)';
        } else {
          text = i + '. ' + text;
        }
      }
      const result:IDocumentHeading = {
        id: parent.id,
        text: text,
        percent: 0,
        sectionElement: undefined
      }
      return result;
    });

    this.results = toc;
    this.results.forEach((h, i) => {
      const sectionElement = document.querySelector(`[id="${h.id}"]`);
      if (sectionElement !== null) {
        this.results[i].sectionElement = sectionElement;
      }
    });

  }

  updateBrowseProgress() {
    const offset =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    const viewPortHeight = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight || 0
    );
    const documentHeight = Math.max(
      document.body.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.clientHeight,
      document.documentElement.scrollHeight,
      document.documentElement.offsetHeight
    );
    const footerHeight = 180;
    const stickyHeader = document.getElementsByTagName("header")[1];
    if (stickyHeader) {
      this.headerHeight = stickyHeader.getBoundingClientRect().height;
    }
    const headerPaddingTop = 0;
    const headerHeight = this.headerHeight + headerPaddingTop;
    const maxHeight =
      documentHeight - (viewPortHeight - headerHeight) - footerHeight;

    const position = offset + headerHeight;

    this.results.forEach((h, i) => {
      if (this.results[i].sectionElement !== undefined) {
        const start = this.results[i].sectionElement!.getBoundingClientRect().top + offset;
        let end = 0;
        if (i < this.results.length - 1) {
          end = this.results[i + 1].sectionElement!.getBoundingClientRect().top + offset;
        } else {
          end = maxHeight;
        }
        const height = end - start;
        if (position > maxHeight) {
          this.results[i].percent = 100;
        } else {
          if (position >= start) {
            if (position <= end) {
              const heightIntersect = position - start;
              this.results[i].percent = this.sanitizePercentage(
                (heightIntersect / height) * 100
              );
            } else {
              this.results[i].percent = 100;
            }
          } else {
            this.results[i].percent = 0;
          }
        }
      }
    });
  }

  private sanitizePercentage(percentage: number) {
    if (percentage < 5) {
      return 0;
    } else if (percentage > 100) {
      return 100;
    } else {
      return Math.round(percentage);
    }
  }

}

export interface IDocumentHeading {
  id: string;
  text: string;
  percent: number;
  sectionElement?: Element;
}
