import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit
} from "@angular/core";

import { IPlaybackStateDTO } from "./audio-playback-state.interface";
import { AudioPlaybackService } from "./audio-playback.service";
import { MinuteSecondsPipe } from "./time-format.pipe";
import { Howler } from "howler";

import { MatSliderChange } from "@angular/material/slider";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MinuteSecondsPipe, AudioPlaybackService],
  //selector: "moa-audio-control",
  styleUrls: ["./audio-control.component.scss"],
  templateUrl: "./audio-control.component.html"
})
export class AudioControlComponent implements OnInit {
  @Input() url = "assets/sample.mp3";
  @Input() identifier = "";
  @Input() smallScreen = false;
  playbackState!: IPlaybackStateDTO;

  constructor(
    private audioService: AudioPlaybackService,
    private cdr: ChangeDetectorRef,
    private minSec: MinuteSecondsPipe
  ) {}

  ngOnInit() {
    this.audioService.currentStateChange.subscribe(data => {
      this.playbackState = data;
      this.cdr.markForCheck();
    });
    Howler.autoUnlock = false;
  }

  play() {
    this.playbackState.loading = true;
    this.audioService.play(this.url);
  }

  unscribeSubscriptions() {
    this.audioService.killSubscriptions();
  }

  pause() {
    this.audioService.pause();
  }

  onSliderChangeEnd(event: MatSliderChange) {
    if (event.value !== null) {
      this.audioService.updateCurrentTime(event.value);
      this.cdr.markForCheck();
    }
  }

  onChange(event: MatSliderChange) {
    if (event.value !== null) {
      this.audioService.seekTo(event.value);
      this.cdr.markForCheck();
    }
  }

  onVolumeChange(event: MatSliderChange) {
    if (event.value !== null) {
      this.audioService.onVolumeChange(event.value);
    }
  }

  /*
  onSliderChangeEnd(change: { value: number }) {
    this.audioService.updateCurrentTime(change.value);
    this.cdr.markForCheck();
  }

  onChange(event: { value: number }) {
    this.audioService.seekTo(event.value);
    this.cdr.markForCheck();
  }

  onVolumeChange(event: { value: number }) {
    this.audioService.onVolumeChange(event.value);
  }
  */
}
