import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "highlighter",
  pure: true
})
export class HighlighterPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    if (value == null || args == null || typeof args[0] !== "string") {
      return value;
    }

    const pattern = args[0]
      .replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&")
      .split(" ")
      .filter((t: string) => t.length > 0)
      .join("|");

    const regex = new RegExp(pattern, "gi");

    return value.replace(regex, (match: string) => `<b>${match}</b>`);
  }
}
