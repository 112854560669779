import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "minuteSeconds"
})
export class MinuteSecondsPipe implements PipeTransform {
  transform(value: number): string {
    if (value) {
      let minutes: number | string = Math.floor(value / 60);
      let seconds: number | string = value - minutes * 60;
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      return minutes + ":" + seconds;
    }
    return "0";
  }
}
