<ng-content></ng-content>
<mat-form-field appearance="fill" floatLabel="never">
  <mat-label>Type in medicine name or active ingredient</mat-label>
  <input name="document-search" autocomplete="off" matInput [formControl]="ctrl" [matAutocomplete]="auto"
    (blur)="active.next(false);" (focus)="active.next(true); hideAutocompletePanel();">
  <!-- <button mat-button matSuffix>Search</button> -->
</mat-form-field>
<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayOption"
  (optionSelected)="selected($event.option.value.Identifier, $event.option.value)">
  <mat-option *ngFor="let option of options | async" [value]="option"
    style="height: auto; line-height: inherit; padding: 8px 24px">
    <div>
      <span [innerHTML]="option.TradeName | highlighter:ctrl.value"></span>
      <br>
      <small
        [innerHTML]="option.ActiveIngredientName | highlighter:ctrl.value" style="color: rgba(0,0,0,0.54)">
      </small>
      <span *ngFor="let name of option.ActiveIngredientOtherNames">
        <br>
        <small
          [innerHTML]="name | highlighter:ctrl.value" style="color: rgba(0,0,0,0.54)">
        </small>
      </span>
    </div>
  </mat-option>
</mat-autocomplete>
