import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { HttpClient } from "@angular/common/http";
import { Component, OnInit, Input } from "@angular/core";
import { PDFProgressData, PDFDocumentProxy } from "pdfjs-dist";
import { Observable, of } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";

import { DocumentMeta } from "../data-models/abstractions";
import { environment } from "../../environments/environment";

// tslint:disable-next-line: prefer-on-push-component-change-detection
@Component({
  //styleUrls: ["./pdf-viewer.component.scss"],
  templateUrl: "./pdf-viewer.component.html"
})
export class PdfViewerComponent implements OnInit {
  private readonly _http: HttpClient;
  @Input() identifier = "";

  baseUrl = environment.apiBase;
  
  busy = true;
  meta?: DocumentMeta;
  src = "";
  showNotFound = false;
  smallScreen = false;
  constructor(
    http: HttpClient,
    private breakpointObserver: BreakpointObserver
  ) {
    this._http = http;
  }

  ngOnInit() {
    this.getDocumentMeta(this.identifier)
      /*
      .pipe(
        catchError(e => {
          this.showNotFound = true;
          return of(undefined);
        })
      )
      */
      .subscribe(response => {
        if (response) {
          this.src = `${this.baseUrl}/documents/${response.Identifier}?format=pdf`;
        }
      },
      error => {
        this.showNotFound = true;
      });

    this.breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small])
      .subscribe(result => {
        this.smallScreen = result.matches ? true : false;
      });
  }

  onError(e: any) {
    this.showNotFound = true;
  }

  private getDocumentMeta(id: string): Observable<DocumentMeta> {
    return this._http.get<DocumentMeta>(`${this.baseUrl}/documents/${id}`);
  }

  onProgress(pdfData: PDFProgressData) {
    this.busy = true;
    this.showNotFound = false;
  }

  callBackFn(pdf: PDFDocumentProxy) {
    this.busy = false;
    this.showNotFound = false;
  }
}
