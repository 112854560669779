import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { HttpClient } from "@angular/common/http";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { Observable, of as observableOf } from "rxjs";
import { catchError, debounceTime, filter, switchMap } from "rxjs/operators";

import { DocumentMeta } from "./abstractions";
import { environment } from "../../environments/environment";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  // selector: "moa-document-search",
  styleUrls: ["./document-search.component.scss"],
  templateUrl: "./document-search.component.html"
})
export class DocumentSearchComponent implements OnInit {
  ctrl = new FormControl();
  options!: Observable<any>;
  
  apiBase = environment.apiBase + '/documents';
  
  mobileScreen = false;
  private readonly _breakpointObserver: BreakpointObserver;
  private readonly _cdr: ChangeDetectorRef;
  @ViewChild(MatAutocompleteTrigger, { static: false })
  auto?: MatAutocompleteTrigger;

  @Input() kind = 1;
  @Output() active = new EventEmitter<boolean>();

  constructor(
    private http: HttpClient,
    breakpointObserver: BreakpointObserver,
    cdr: ChangeDetectorRef
  ) {
    this._breakpointObserver = breakpointObserver;
    this._cdr = cdr;
  }

  ngOnInit() {
    this.options = this.ctrl.valueChanges.pipe(
      filter(v => typeof v === "string" && v.length >= 2),
      debounceTime(150),
      switchMap(v =>
        this.http
          .get(`${this.apiBase}`, {
            params: { query: v, kind: this.kind.toString() }
          })
          .pipe(
            catchError(() => {
              return observableOf([]);
            })
          )
      )
    );
    this._breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small])
      .subscribe(result => {
        this.mobileScreen = result.breakpoints["(max-width: 599.99px)"]
          ? true
          : false;
        this._cdr.markForCheck();
      });
  }

  displayOption(v: DocumentMeta) {
    return v ? v.TradeName : "";
  }

  selected(id: string, op: any) {
    if (this.mobileScreen && !op.HasHtml) {
      const el = document.createElement("a");
      el.setAttribute("download", "");
      el.setAttribute(
        "href",
        `${this.apiBase + "/" + id + "?format=pdf"}`
      );
      el.click();
    } else {
      window.location.href = `/${this.kind.toString() == "1" ? "consumer-information" : "product-information"}/document/${id}`;
    }
  }

  hideAutocompletePanel() {
    setTimeout(() => {
      if (this.auto) {
        if (this.auto.panelOpen) {
          this.auto.closePanel();
        }
      }
    }, 100);
  }
}
