<aside [ngClass]="{ 'desktop': !smallScreen, 'mobile': smallScreen }" 
  [ngStyle]="{ 'top': (headerHeight + 20) + 'px' }">
  <ul>
    <li *ngFor="let item of results">
      <div class="background"
        [ngStyle]="{ 'width': item.percent + '%' }">
        &nbsp;
      </div>
      <div class="heading-text">
        <a href="{{currentPage}}#{{item.id}}">{{ item.text }}</a>
      </div>
      <div class="heading-checkbox">
        <mat-icon *ngIf="item.percent >= 100">done</mat-icon>
      </div>
    </li>
  </ul>
</aside>

